/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

 i.fa {
    font-family: fontawesome !important;
  }

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/glyphicons-halflings/scss/glyphicons-halflings';
@import '../../node_modules/font-awesome/scss/font-awesome';
//@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import '../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css';
@import '../fonts/flaticon/flaticon';
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
@import '../../node_modules/messenger/build/css/messenger.css';
@import '../../node_modules/messenger/build/css/messenger-theme-air.css';
@import '../../node_modules/nvd3/build/nv.d3.css';
@import '../../node_modules/rickshaw/rickshaw.css';
@import '../../node_modules/react-table/react-table.css';
@import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
@import '../../node_modules/react-tagsinput/react-tagsinput.css';
@import '../../node_modules/react-sortable-tree/style.css';
@import '../../node_modules/rickshaw/rickshaw.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';


@import 'mixins';
@import 'base';
@import 'auth';
@import 'overrides';
@import 'general';
@import 'utils';

